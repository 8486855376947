import React, { useState } from 'react';

interface QuoteCardProps {
  text: string;
  quote: string;
  authorImage: string;
  authorName: string;
  authorTitle: string;
}

const QuoteCard: React.FC<QuoteCardProps> = ({ text, quote, authorImage, authorName, authorTitle }) => {
  const [isOpen, setIsOpen] = useState(true);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div
      className={`bg-white rounded-lg shadow-lg p-6 cursor-pointer transition-all duration-300 ${
        isOpen ? 'max-h-96' : 'max-h-24 overflow-hidden'
      }`}
      onClick={toggleOpen}
    >
      <figure className="max-w-screen-md mx-auto text-justify">
        <svg
          className="w-8 h-4 mx-auto mb-3 text-gray-400 dark:text-gray-600"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 18 14"
        >
          <path d="M6 0H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3H2a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Zm10 0h-4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3h-1a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Z" />
        </svg>
        <blockquote>
          <p className="text-base md:text-xl italic font-medium text-gray-900 dark:text-white">{quote}</p>
        </blockquote>
        <figcaption className="flex items-center justify-center mt-4 space-x-3 rtl:space-x-reverse">
          <img className="w-6 h-6 rounded-full" src={authorImage} alt="profile picture" />
          <div className="flex items-center divide-x-2 rtl:divide-x-reverse divide-gray-500 dark:divide-gray-700">
            <cite className="pe-3 font-medium text-gray-900 dark:text-white">{authorName}</cite>
            <cite className="ps-3 text-xs md:text-sm text-gray-500 dark:text-gray-400">{authorTitle}</cite>
          </div>
        </figcaption>
      </figure>
    </div>
  );
};

export default QuoteCard;