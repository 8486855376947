import React from 'react';
import MoreInfo from './MoreInfo';

const ContactSection: React.FC = () => {
  const contacts = [
    { name: "Carla", phone: "+55 (31) 99623-0379" },
    { name: "Maya", phone: "+55 (31) 99629-3844" },
    { name: "Raquel", phone: "+55 (31) 98449-5047" },
    { name: "Zileia", phone: "+55 (31) 99139-9050" },
  ];

  return (
    <section id="contact" className="p-4">
      <h2 className="text-4xl font-bold text-center mb-4 px-4">Contato</h2>
      <p className="text-lg font-bold text-left mb-4 py-6">Tem alguma dúvida sobre o evento? Envie sua pergunta abaixo e responderemos o mais breve possível.</p>

      <div className="flex flex-col md:flex-row gap-8 ">
        {/* Formulário */}
        <form action="mailto:seuemail@exemplo.com" method="post" encType="text/plain" className="flex-1 space-y-4  bg-gray-100 p-4">
          <label htmlFor="name" className="block text-lg font-bold">Nome:</label>
          <input type="text" id="name" name="name" required className="w-full p-2 border border-gray-300" />

          <label htmlFor="email" className="block text-lg font-bold">E-mail:</label>
          <input type="email" id="email" name="email" required className="w-full p-2 border border-gray-300" />

          <label htmlFor="message" className="block text-lg font-bold">Mensagem:</label>
          <textarea id="message" name="message" rows={6} required className="w-full p-2 border border-gray-300"></textarea>

          <input type="submit" value="Enviar" className="bg-blue-600 text-white p-4 pl-20 pr-20 rounded cursor-pointer" />
        </form>

        {/* Mais Informações */}
        <section id="moreinfo" className="flex-1 bg-gray-100 p-8 rounded-lg shadow-md">
          <MoreInfo title="Mais Informações:" contacts={contacts} />
        </section>
      </div>
    </section>
  );
};

export default ContactSection;