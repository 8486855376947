import React from "react";

const AboutSection: React.FC = () => {
  return (
    <section
      id="#about"
      className="p-4 flex flex-col items-center justify-center"
    >
      <h2 className="text-5xl font-bold text-center mb-6">Sobre o Evento</h2>
      <p className="font-book-antiqua text-gray-700 text-3xl text-center">
        <strong>Bem-vindo ao 8º Encontro de Evangelho</strong>
      </p>
      <p className="font-book-antiqua text-gray-700 text-3xl text-center">
        <strong>das Casa Espíritas de Sete Lagoas e Região!</strong>
      </p>
      <p className="font-monotype-corsiva text-gray-700 text-2xl text-center pt-6">
        No turbilhão de cores, ritmos e folias que caracterizam o carnaval, há
        uma dança sutil que transcende os confetes e serpentinas.
      </p>

      <p className="font-monotype-corsiva text-gray-700 text-2xl text-center">
        É o carnaval da alma, onde os corações se encontram em um baile
        silencioso, movidos pela música da espiritualidade.
      </p>
      <p className="font-monotype-corsiva text-gray-700 text-2xl text-center">
        Nossas Casas Espíritas, como faróis de amor e compreensão, promovem um
        Encontro de Evangelho nesses dias festivos.
      </p>
      <p className="font-monotype-corsiva text-gray-700 text-2xl text-center">
        Não é um encontro comun, mas sim um mergulho profundo nas águas da
        reflexão e da fraternidade.
      </p>
      <p className="font-monotype-corsiva text-gray-700 text-2xl text-center pt-2">
        <strong>1. A Máscara da Ilusão:</strong> Nesse carnaval da alma,
        deixamos de lado as máscaras físicas
      </p>
      <p className="font-monotype-corsiva text-gray-700 text-2xl text-center">
        e nos deparamos com as máscaras invisíveis que usamos diariamente. A
        máscara da vaidade, do orgulho, da indiferença.
      </p>
      <p className="font-monotype-corsiva text-gray-700 text-2xl text-center pt-2">
        <strong>2. O Bloco da Caridade:</strong> Enquanto os blocos
        carnavalescos desfilam pelas ruas,
        <p className="font-monotype-corsiva text-gray-700 text-2xl text-center">
          nosso bloco da caridade se forma. Nele, não há fantasias
          extravagantes, mas sim gestos simples de amor ao próximo.
        </p>
        <p className="font-monotype-corsiva text-gray-700 text-2xl text-center">
          Distribuímos sorrisos, abraços e palavras de consolo. Afinal, a
          verdadeira festa está em servir.
        </p>
        <p className="font-monotype-corsiva text-gray-700 text-2xl text-center pt-2">
          <strong>3. A Marchinha da Esperança:</strong> No Encontro de
          Evangelho, cantamos a marchinha da esperança.
        </p>
        <p className="font-monotype-corsiva text-gray-700 text-2xl text-center">
          Ela ecoa nos corações, lembrando-nos de que, mesmo nas adversidades,
        </p>
        <p className="font-monotype-corsiva text-gray-700 text-2xl text-center">
          há luz no fim do túnel. A fé nos impulsiona a seguir adiante,
          confiantes de que o amor divino nos guia.
        </p>
      </p>

      <p className="font-monotype-corsiva text-gray-700 text-2xl text-center pt-2">
        <strong>4. O Confete da Gratidão:</strong> Cada palavra de estudo, cada
        abraço fraterno, é um confete lançado ao vento da gratidão.
      </p>
      <p className="font-monotype-corsiva text-gray-700 text-2xl text-center">
        Agradecemos pela oportunidade de aprender, de compartilhar, de sermos
        instrumentos de paz.
      </p>
      <p className="font-monotype-corsiva text-gray-700 text-2xl text-center">
        No Encontro de Evangelho, somos todos confetes coloridos na grande festa
        da vida.
      </p>

      <p className="font-monotype-corsiva text-gray-700 text-2xl text-center pt-2">
        <strong>5. O Desfile da Solidariedade:</strong> Enquanto os carros
        alegóricos desfilam na avenida, nosso desfile é silencioso e profundo.
      </p>
      <p className="font-monotype-corsiva text-gray-700 text-2xl text-center">
        Desfilamos com a solidariedade, oferecendo apoio aos que sofrem,
      </p>
      <p className="font-monotype-corsiva text-gray-700 text-2xl text-center">
        estendendo a mão aos que caíram.
      </p>
      <p className="font-monotype-corsiva text-gray-700 text-2xl text-center">
        Somos os anjos disfarçados de seres humanos, levando consolo e
        esperança.
      </p>

      <p className="font-book-antiqua text-gray-700 text-2xl text-center pt-2">
        Que nesse carnaval da alma, possamos dançar ao som da espiritualidade,
      </p>
      <p className="font-book-antiqua text-gray-700 text-2xl text-center">
        despidos de preconceitos e julgamentos, celebrando a verdadeira alegria
      </p>
      <p className="font-book-antiqua text-gray-700 text-2xl text-center">
        que brota do coração. E que o Encontro de Evangelho seja nosso bloco
        mais especial,
      </p>
      <p className="font-book-antiqua text-gray-700 text-2xl text-center">
        onde todos são bem-vindos, independentemente da fantasia que vestem.
      </p>
    </section>
  );
};

export default AboutSection;
