import React from 'react';

const Footer: React.FC = () => {
  return (
    <footer className="bg-gray-800 text-white p-4 text-center">
      <p>&copy; 2024 CAPELA DO SOL.</p>
      <p> Todos os direitos reservados.</p>
      <p>Rod MG 238 KM 22</p> 
      <p><strong>2.5 km após a Iveco entrada a esquerda</strong></p>
    </footer>
  );
};

export default Footer;