import React from 'react';

interface ContactInfo {
  name: string;
  phone: string;
}

interface MoreInfoProps {
  title: string;
  contacts: ContactInfo[];
}

const MoreInfo: React.FC<MoreInfoProps> = ({ title, contacts }) => {
  return (
    <div className="bg-gray-100 p-6 rounded-lg shadow-md max-w-md mx-auto">
      <h2 className="text-2xl font-bold text-center mb-4">{title}</h2>
      <div className="space-y-4">
        {contacts.map((contact, index) => (
          <div key={index} className="flex items-center justify-between bg-white p-4 rounded-lg shadow-sm">
            <span className="text-gray-800 font-semibold">{contact.name}:</span>
            <span className="text-blue-600">{contact.phone}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MoreInfo;