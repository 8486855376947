import React, { useState, useEffect } from 'react';

interface CarouselItem {
  image: string;
  title: string;
  text: string;
}

interface CarouselProps {
  items: CarouselItem[];
  interval?: number; // Intervalo de tempo em milissegundos
}

const Carousel: React.FC<CarouselProps> = ({ items, interval = 5000 }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % items.length);
    }, interval);

    return () => clearInterval(timer); // Limpa o intervalo quando o componente é desmontado
  }, [items.length, interval]);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % items.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + items.length) % items.length);
  };

  return (
    <div className="relative w-full max-w-2xl mx-auto">
      <div className="overflow-hidden">
        <div
          className="flex transition-transform duration-500 ease-in-out"
          style={{ transform: `translateX(-${currentIndex * 100}%)` }}
        >
          {items.map((item, index) => (
            <div key={index} className="w-full flex-shrink-0 relative">
              {/* Imagem com object-fit: contain para caber no espaço sem cortes */}
              <img
                src={item.image}
                alt={`Slide ${index}`}
                className="w-full h-96 object-contain" // Usa object-fit: contain
              />
              {index === currentIndex && (
                <div className="absolute inset-x-0 bottom-0 p-4 text-white bg-black bg-opacity-50">
                  <h2 className="text-2xl font-bold mb-2">{item.title}</h2>
                  <p className="text-lg">{item.text}</p>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      <button
        onClick={prevSlide}
        className="absolute top-1/2 left-0 transform -translate-y-1/2 bg-gray-800 text-white p-2 rounded-full opacity-50 hover:opacity-100"
      >
        &#10094;
      </button>
      <button
        onClick={nextSlide}
        className="absolute top-1/2 right-0 transform -translate-y-1/2 bg-gray-800 text-white p-2 rounded-full opacity-50 hover:opacity-100"
      >
        &#10095;
      </button>
    </div>
  );
};

export default Carousel;