import React from 'react';

const VideoSection: React.FC = () => {
  return (
    <section className="p-4 flex justify-center">
      <div className="w-full max-w-4xl aspect-w-16 aspect-h-9">
        <video
          controls
          controlsList="nodownload nofullscreen"
          width="100%"
          height="auto"
          className="w-full h-full"
        >
          <source src="assets/video/apresenta.mp4" type="video/mp4" />
          Seu navegador não suporta o elemento de vídeo.
        </video>
      </div>
    </section>
  );
};

export default VideoSection;