import React from 'react';

const Header: React.FC = () => {
  return (
    <header className="bg-custom-green text-white">
      <div className="container mx-auto px-4 flex flex-col md:flex-row justify-between items-center py-4">
        <h1 className="text-4xl md:text-8xl font-bold text-center md:text-left">ENCONTRO DE CARNAVAL</h1>
        <img src="assets/images/capela.jpg" alt="Capela" className="w-20 h-20 md:w-40 md:h-40" />
      </div>    
      <nav className="bg-custom-green text-white py-4">
        <div className="container mx-auto px-4 flex justify-end">
          <ul className="flex flex-wrap justify-center space-x-4">
            <li><a href="#about" className="text-lg font-bold text-white">Sobre</a></li>
            <li><a href="#quotes" className="text-lg font-bold text-white">Citações</a></li>
            <li><a href="#activities" className="text-lg font-bold text-white">Apresentação</a></li>
            <li><a href="#topics" className="text-lg font-bold text-white">Palestras</a></li>
            <li><a href="#signup" className="text-lg font-bold text-white">Inscrição</a></li>
            <li><a href="#contact" className="text-lg font-bold text-white">Contato</a></li>
          </ul>
        </div>
      </nav>
    </header>
  );
};

export default Header;